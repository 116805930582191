<template>
  <div>
    <Loading v-show="loading"></Loading>
    <div
      ref="lineChart1"
      :style="{ width: '80%', height: '400px'}"
    ></div>
    <br>
    <div
      ref="lineChart2"
      :style="{ width: '80%', height: '400px'}"
    ></div>
    <br>
    <div
      ref="lineChart3"
      :style="{ width: '100%', height: '800px'}"
    ></div>
    <br>
    <div
      ref="lineChart4"
      :style="{ width: '100%', height: '800px'}"
    ></div>
  </div>
</template>

<script>
import 'echarts/lib/chart/line'
import { getSessionData, isEmpty } from '@core/utils'
import homeStoreModule from './homeStoreModule'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import Loading from '@/views/components/load/Loading.vue'

const USER_APP_STORE_MODULE_NAME = 'app-home'
const userData = ref([])
const echarts = require('echarts')

export default {
  components: { Loading },
  watch: {
    // userData を watch する
    userData(val) {
      setTimeout(() => {
        this.drawLine(val)
        this.drawLine2(val)
        this.drawLine3(val)
        this.drawLine4(val)
      }, 500)
    },
  },
  created() {
    this.loading = true
    const loginUserId = String(getSessionData('data').id)

    store
      .dispatch('app-home/fetchData', {
        params: {
          loginUserId,
        },
      })
      .then(response => {
        userData.value = response.data.result.data
        this.loading = false

        // userData.value = response.data
      })
      .catch(error => {
        // ログイン画面に遷移する
        console.log(error)
        this.loading = false

        this.$router.push({ name: 'error-login' })
      })
  },
  mounted() {
    // 日付選択コンポーネントを非表示させる
    this.$dateRangePickerDisplayFlag = false
    this.$datePickerDisplayFlag = false

    // 拠点選択コンポーネントを非表示させる
    this.$selectScDisplayFlag = false

    this.$selectDomesticScDisplayFlag = false

    this.$domesticDatePickerDisplayFlag = false
  },
  methods: {
    drawLine(val) {
      const option = {
        grid: {
          left: '3%',
          right: '3%',
          bottom: '3%',
          containLabel: true,
        },
        title: {
          text: val.enterShop.text,
        },
        xAxis: {
          boundaryGap: [0, 0.01],
          type: 'value',
        },
        yAxis: {
          type: 'category',
          data: val.enterShop.yAxis,
        },
        series: [
          {
            data: val.enterShop.seriesData,
            type: 'bar',
            label: {
              normal: {
                show: true,
                formatter: params => {
                  const v = parseFloat(params.value)

                  return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                },
              },
            },
          },
        ],
      }

      let lineChart1 = echarts.getInstanceByDom(this.$refs.lineChart1)
      if (lineChart1 == null) {
        lineChart1 = echarts.init(this.$refs.lineChart1)
      }

      lineChart1.setOption(option, true)
    },

    drawLine2(val) {
      const option = {
        grid: {
          left: '3%',
          right: '3%',
          bottom: '3%',
          containLabel: true,
        },
        title: {
          text: val.approaching.text,
        },
        xAxis: {
          boundaryGap: [0, 0.01],
          type: 'value',
        },
        yAxis: {
          type: 'category',
          data: val.approaching.yAxis,
        },
        series: [
          {
            data: val.approaching.seriesData,
            type: 'bar',
            label: {
              normal: {
                show: true,
                formatter: params => {
                  const v = parseFloat(params.value)

                  return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                },
              },
            },
          },
        ],
      }

      let lineChart2 = echarts.getInstanceByDom(this.$refs.lineChart2)
      if (lineChart2 == null) {
        lineChart2 = echarts.init(this.$refs.lineChart2)
      }

      lineChart2.setOption(option, true)

      if (!isEmpty(val.approaching.seriesData)) {
        this.$refs.lineChart2.style.display = 'block'
      } else {
        this.$refs.lineChart2.style.display = 'none'
      }
    },
    drawLine3(val) {
      const newSeriesData = []

      for (let i = 0; i < Object.values(val.seriesData1).length; i += 1) {
        const element = Object.values(val.seriesData1)[i]

        const newData = {
          name: element.name,
          type: 'line',
          label: {
            normal: {
              show: true,
              formatter: params => {
                const v = parseFloat(params.value)

                return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
              },
            },
          },
          data: element.data,
        }
        newSeriesData.push(newData)
      }

      const lineChartOption = {
        title: {
          left: '0%',
          text: val.seriesData1Text,
        },

        // toolbox: {
        //   bottom: '0',
        //   left: '5%',
        //   feature: {
        //     saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
        //   },
        // },
        tooltip: { trigger: 'axis' },
        legend: {
          // orient: 'vertical',
          bottom: '0',
          left: 'center',
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '20%',
          containLabel: true,
        },
        xAxis: {
          data: val.legendData1,
          axisLabel: {
            interval: 0,
            rotate: 40,
          },
        },
        yAxis: {},
        series: newSeriesData,
      }
      let lineChart3 = echarts.getInstanceByDom(this.$refs.lineChart3)
      if (lineChart3 == null) {
        lineChart3 = echarts.init(this.$refs.lineChart3)
      }

      lineChart3.setOption(lineChartOption, true)
    },
    drawLine4(val) {
      const newSeriesData = []

      for (let i = 0; i < Object.values(val.seriesData2).length; i += 1) {
        const element = Object.values(val.seriesData2)[i]

        const newData = {
          name: element.name,
          type: 'line',
          label: {
            normal: {
              show: true,
              formatter: params => {
                const v = parseFloat(params.value)

                return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
              },
            },
          },
          data: element.data,
        }
        newSeriesData.push(newData)
      }

      const lineChartOption = {
        title: {
          left: '0%',
          text: val.seriesData2Text,
        },

        // toolbox: {
        //   bottom: '0',
        //   left: '5%',
        //   feature: {
        //     saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
        //   },
        // },
        tooltip: { trigger: 'axis' },
        legend: {
          // orient: 'vertical',
          bottom: '0',
          left: 'center',
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '20%',
          containLabel: true,
        },
        xAxis: {
          data: val.legendData2,
          axisLabel: {
            interval: 0,
            rotate: 40,
          },
        },
        yAxis: {},
        series: newSeriesData,
      }
      let lineChart4 = echarts.getInstanceByDom(this.$refs.lineChart4)
      if (lineChart4 == null) {
        lineChart4 = echarts.init(this.$refs.lineChart4)
      }

      lineChart4.setOption(lineChartOption, true)
    },

    // drawLine5(val) {
    //   const option = {
    //     grid: {
    //       left: '3%',
    //       right: '3%',
    //       bottom: '3%',
    //       containLabel: true,
    //     },
    //     title: {
    //       text: val.secondFloor.text,
    //     },
    //     xAxis: {
    //       boundaryGap: [0, 0.01],
    //       type: 'value',
    //     },
    //     yAxis: {
    //       type: 'category',
    //       data: val.secondFloor.yAxis,
    //     },
    //     series: [
    //       {
    //         data: val.secondFloor.seriesData,
    //         type: 'bar',
    //         label: {
    //           normal: {
    //             show: true,
    //             formatter: params => {
    //               const v = parseFloat(params.value)

    //               return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
    //             },
    //           },
    //         },
    //       },
    //     ],
    //   }

    //   let lineChart5 = echarts.getInstanceByDom(this.$refs.lineChart5)
    //   if (lineChart5 == null) {
    //     lineChart5 = echarts.init(this.$refs.lineChart5)
    //   }

    //   lineChart5.setOption(option, true)

    //   if (!isEmpty(val.secondFloor.seriesData)) {
    //     this.$refs.lineChart5.style.display = 'block'
    //   } else {
    //     this.$refs.lineChart5.style.display = 'none'
    //   }
    // },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, homeStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    return { userData, loading: false }
  },
}
</script>

<style>
</style>
